<template>
  <Default>
    <div class="usuarios">
    <banner :fondo="newUser" titulo="Registrar usuario" descripcion=""></banner>

    <section class="main__content">
      <div class="card">
        <div class="card-header">
          <button
            type="button"
            class="btn btn-outline-danger btn-sm"
            @click="$router.go(-1)"
          >
            Atrás
          </button>
        </div>

        <div class="card-body">
          <form @submit.prevent="submit()">
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="form-group">
                  <label for="tipoDocumento">Tipo de usuario</label>
                  <select
                    class="form-control"
                    required
                    v-model="form.tipoUsuario"
                  >
                    <option
                      :value="item.value"
                      v-for="(item, index) in tiposUsuarios"
                      :key="index"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>

                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="nombres">Nombres</label>
                      <input
                        type="text"
                        id="nombres"
                        class="form-control"
                        required
                        v-model="form.nombres"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="apellidos">Apellidos</label>
                      <input
                        type="text"
                        id="apellidos"
                        class="form-control"
                        required
                        v-model="form.apellidos"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="tipoDocumento">Tipo de documento</label>
                      <select
                        class="form-control"
                        required
                        v-model="form.tipoDocumento"
                      >
                        <option
                          :value="item.value"
                          v-for="(item, index) in tiposDocumento"
                          :key="index"
                        >
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="nroDocumento">Nro. documento</label>
                      <input
                        type="text"
                        id="nroDocumento"
                        class="form-control"
                        required
                        v-model="form.nroDocumento"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        :readonly="!isHabilitated"
                        type="email"
                        id="email"
                        @keyup="lowerEmail"
                        class="form-control"
                        v-model.trim="form.email"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="celular">Celular</label>
                      <input
                        type="number"
                        id="celular"
                        class="form-control"
                        required
                        v-model="form.celular"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-row" v-if="isHabilitated">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="email">Contraseña</label>
                      <input
                        type="password"
                        id="password"
                        class="form-control"
                        v-model="password"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="password2">Confirmar contraseña</label>
                      <input
                        type="password"
                        id="password2"
                        class="form-control"
                        required
                        v-model="password2"
                      />
                      <span
                        class="small text-danger"
                        v-if="password != password2 && password2.length >= 1"
                        >Las contraseñas deben coincidir</span
                      >
                    </div>
                    <div class="form-group text-right">
                      <p class="small text-danger" v-if="error">{{ error }}</p>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="banco">Banco</label>
                      <input
                        type="text"
                        id="banco"
                        class="form-control"
                        v-model="form.banco"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="nrocuenta">Nro. Cuenta</label>
                      <input
                        type="text"
                        id="nrocuenta"
                        class="form-control"
                        v-model="form.nroCuenta"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="cci">CCI</label>
                      <input
                        type="text"
                        id="cci"
                        class="form-control"
                        v-model="form.cci"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <vue-dropzone
                    ref="dropzoneImage"
                    id="dropzone"
                    :options="dropzoneOptions"
                    @vdropzone-complete="asignarImagen"
                    v-show="mostrarDropzone"
                  ></vue-dropzone>

                  <section v-if="preview">
                    <div class="preview">
                      <img
                        :src="preview"
                        alt="Imagen de usuario"
                        class="img-fluid"
                      />
                    </div>

                    <div
                      class="d-flex justify-content-between align-items-center mt-3"
                    >
                      <a
                        href=""
                        @click.prevent="removeImage()"
                        class="small text-danger d-inline-block mb-2"
                        >Cambiar imagen</a
                      >
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-end pr-md-5">
              <button
                type="submit"
                class="btn btn-primary px-4"
                :disabled="loading || $v.$invalid"
              >
                <template v-if="loading">
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Cargando...</span>

                  <span class="d-inline-block ml-2">Cargando</span>
                </template>

                <span v-else>Registrar Nuevo Usuario</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
  </Default>
</template>

<script>
import UpdateUsuario from "@/apollo/mutations/usuarios/UpdateUsuario.gql";
import CrearUsuario from "@/apollo/mutations/usuarios/CrearUsuario.gql";
import Default from "@/layouts/default.vue";

import { validationMixin } from "vuelidate";
const {
  required,
  email,
  sameAs,
  requiredIf,
} = require("vuelidate/lib/validators");

import Banner from "@/components/global/Banner";
import newUser from "@/assets/banner/newUser.jpg";

const defaultForm = {
  tipoUsuario: null,
  tipoDocumento: null,
  nroDocumento: null,
  nombres: null,
  apellidos: null,
  email: null,
  celular: null,

  foto: null,
  banco: null,
  nroCuenta: null,
  cci: null,
};

export default {
  data() {
    return {
      tiposDocumento: [
        {
          value: null,
          text: "Seleccione",
        },
        {
          value: 1,
          text: "DNI",
        },
        {
          value: 2,
          text: "Pasaporte",
        },
        {
          value: 3,
          text: "Carnet de extranjería",
        },
      ],
      tiposUsuarios: [
        {
          value: null,
          text: "Seleccione",
        },
        {
          value: 1,
          text: "Administrador",
        },
        {
          value: 2,
          text: "Supervisor",
        },
        {
          value: 3,
          text: "Contratista",
        },
        {
          value: 4,
          text: "Finanzas",
        },
      ],
      form: { ...defaultForm },
      password: "",
      password2: "",
      mostrarDropzone: true,
      preview: null,
      newUser: newUser,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 145,
        thumbnailHeight: 145,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastre o haga click aquí</p>
                              <p class="form-text">Archivos permitidos: .jpg, .jpeg, .png</p>
                            `,
      },
      loading: false,
      error: null,
      isHabilitated: false,
    };
  },
  validations: {
    form: {
      tipoUsuario: { required },
      tipoDocumento: { required },
      nroDocumento: { required },
      nombres: { required },
      apellidos: { required },
      email: { required, email },
      celular: { required },
      cci: {
        required: requiredIf(function(form) {
          return form.tipoUsuario == 3;
        }),
      },
      banco: {
        required: requiredIf(function(form) {
          return form.tipoUsuario == 3;
        }),
      },
      nroCuenta: {
        required: requiredIf(function(form) {
          return form.tipoUsuario == 3;
        }),
      },
    },
    password: {
      required: requiredIf(function(form) {
        return this.$route.name == "usuarios-crear";
      }),
    },
    password2: {
      required: requiredIf(function(form) {
        return this.$route.name == "usuarios-crear";
      }),
      sameAsPassword: sameAs("password"),
    },
  },
  components: {
    Banner, Default
  },
  mixins: [validationMixin],
  mounted() {
    if (this.$route.name == "usuarios-editar" && !this.$route.params.userData) {
      this.$router.push({ name: "usuarios" });
    } else if (this.$route.name == "usuarios-crear") {
      this.isHabilitated = true;
    } else if (
      this.$route.name == "usuarios-editar" &&
      this.$route.params.userData
    ) {
      const user = this.$route.params.userData;
      this.form = {
        userId: user.userId,
        tipoUsuario: user.tipoUsuario,
        tipoDocumento: user.tipoDocumento,
        nroDocumento: user.nroDocumento,
        nombres: user.nombres,
        apellidos: user.apellidos,
        email: user.email,
        celular: user.celular,
        foto: user.foto,
        banco: user.banco,
        nroCuenta: user.nroCuenta,
        cci: user.cci,
      };

      this.mostrarDropzone = false;
      this.preview = user.foto;
      this.isHabilitated = false;
    }
  },
  methods: {
    getUser() {},
    lowerEmail(email) {
      this.form.email = this.form.email.toLowerCase().trim();
    },
    asignarImagen(file) {
      // Crea una url local para mostrar imagen previa
      this.preview = URL.createObjectURL(file);

      this.form.foto = file;

      this.$refs.dropzoneImage.disable();

      this.mostrarDropzone = false;
    },
    removeImage() {
      this.preview = "";

      this.$refs.dropzoneImage.removeAllFiles();
      this.$refs.dropzoneImage.enable();

      this.mostrarDropzone = true;
    },
    submit() {
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;

      let {
        userId,
        tipoUsuario,
        tipoDocumento,
        nroDocumento,
        nombres,
        apellidos,
        email,
        celular,
        foto,
        banco,
        nroCuenta,
        cci,
      } = this.form;

      let { password } = this;

      let input = {};

      if (this.$route.name == "usuarios-crear") {
        input = {
          tipoUsuario,
          tipoDocumento,
          nroDocumento,
          nombres,
          apellidos,
          email,
          celular,
          password,
          banco,
          nroCuenta,
          cci,
          estado: 1,
        };
      } else {
        if (typeof foto == "string") foto = null;

        input = {
          userId,
          // tipoUsuario,
          tipoDocumento,
          nroDocumento,
          nombres,
          apellidos,
          // email,
          celular,
          // password,
          banco,
          nroCuenta,
          cci,
          // estado: 1,
        };
      }

      this.$apollo
        .mutate({
          mutation:
            this.$route.name == "usuarios-crear" ? CrearUsuario : UpdateUsuario,
          variables: {
            input,
            foto,
          },
          errorPolicy: "all",
        })
        .then((response) => {
          if (response.errors) {
            let codigoError = response.errors[0].debugMessage;

            if (codigoError === "INICIE_CORREO") {
              this.error = "El correo ya está registrado";
            }

            this.loading = false;

            return;
          }

          this.loading = false;

          this.$toast.success(
            `${this.form.nombres} ${this.form.apellidos} fue registrado`,
            {
              // override the global option
              position: "top-right",
            }
          );

          this.form = { ...defaultForm };

          this.$router.push({ name: "usuarios" });
        });
    },
  },
};
</script>

<style lang="scss"></style>
